import Login from '../../components/Login/index';

const AuthPage = () => {
	return (
		<>
			<Login />
		</>
	);
};

export default AuthPage;
