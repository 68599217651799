import AuthPage from '../../pages/AuthPage';

const Auth = () => {
	return (
		<div>
			<AuthPage></AuthPage>
		</div>
	);
};

export default Auth;
